body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  scrollbar-color: #282828 transparent; /* thumb and track color */
  scrollbar-width: thin; /* You can set this to "auto" or "thin" */
}


/* The scrollbar itself */
::-webkit-scrollbar {
    width: 6px; /* Adjust width for vertical scrollbar */
    height: 6px; /* Adjust height for horizontal scrollbar */
}

/* The scrollbar's thumb (the draggable scrolling handle) */
::-webkit-scrollbar-thumb {
    background-color: #282828; /* Darker shade for the thumb */
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

/* The scrollbar's track (the background) */
::-webkit-scrollbar-track {
    background-color: transparent; /* Darker background for the track */
    border-radius: 10px;
}

/* Hover effect for the thumb */
::-webkit-scrollbar-thumb:hover {
    background-color: #6A6A6A; /* Lighten a bit when hovered */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
